import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/sine1.jpg"
import Img2 from "../../images/sine2.jpg"
import Img3 from "../../images/sine3.jpg"
import Img4 from "../../images/sine4.jpg"
import Head from "../../components/head"

const klizneSine = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
    {
      original: `${Img4}`,
      thumbnail: `${Img4}`,
    },
  ]
  return (
    <Layout>
      <Head title="Klizne Šine" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Klizne šine</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Klizne šine
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div
            className="columns products-list end-section"
            style={{ alignItems: "center" }}
          >
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>Klizne šine</b> se proizvode na linijama za profilisanje od
                toplovaljanje, hladnovaljane i pocinkovane trake.
                <br />
                Izrađuju se u dužini od 6 m.
              </p>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default klizneSine
